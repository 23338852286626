<template>
  <div>
    <RelatedLetters
      :letterInfo="letterInfo"
      :letters="letters"
      :relatedLetters="relatedLetters"
      :type="'internal'"
      :isLoaded="isLoaded"
      @getInfo="getInfo"
    />
  </div>
</template>
<script>
import Axios from "axios";
import RelatedLetters from "../../../components/letters/RelatedLetters.vue";
export default {
  components: {
    RelatedLetters,
  },
  data() {
    return {
      letterInfo: {},
      letters: [],
      relatedLetters: [],
      isLoaded: false,
    };
  },
  methods: {
    getInfo() {
      Axios.post(
        this.baseUrl + "/clinic/secretariat/letter/getInfo",
        {
          letterId: this.$route.params.id,
        },
        {
          headers: {
            Authorization: "Bearer: " + localStorage.getItem("token"),
          },
        }
      )
        .then((res) => {
          if (res.status == 200) {
            this.letterInfo = res.data;
            this.isLoaded = true;
          } else {
            const title = (
              <div>
                <h5>خطا </h5>
                <span>مشکلی پیش آمده. مجددا امتحان کنید.</span>
              </div>
            );
            this.toast(title, "error");
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
        });
    },
  },
  mounted() {
    this.getInfo();
  },
};
</script>
